<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-col class="mt-6 pb-0" cols="12">
      <v-card>
        <v-card-actions>
          <v-icon @click="close">mdi-arrow-left-circle</v-icon>

          <v-spacer></v-spacer>

          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-title class="h4">Billing Defaults</v-card-title>
        <v-card-text>
          <v-col class="pr-4" cols="12" md="6">
            <v-select
              v-model="vm.billing.contactType"
              :items="contactTypes"
              label="Contact type"
              data-vv-as="contactType"
              name="contactType"
              v-validate="'required'"
              :error-messages="errors.collect('contactType')"
            ></v-select>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-select
              v-model="vm.billing.gstType"
              :items="gstTypes"
              label="GST type"
              data-vv-as="gstType"
              name="gstType"
              item-text="description"
              v-validate="'required'"
              return-object
              :error-messages="errors.collect('gstType')"
            ></v-select>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-select
              v-model="vm.billing.claimType"
              :items="claimTypes"
              label="Claim type"
              data-vv-as="claimType"
              name="claimType"
              item-text="description"
              v-validate="'required'"
              return-object
              :error-messages="errors.collect('claimType')"
            ></v-select>
          </v-col>
          <v-col
            class="pr-4"
            cols="12"
            md="6"
            v-if="vm.billing.claimType!==undefined && vm.billing.claimType.code=='CANC'"
          >
            <v-select
              v-model="vm.billing.cancellationReason"
              :items="cancelationReasons"
              label="Cancellation Reason"
              item-text="description"
              data-vv-as="cancellationReason"
              name="cancellationReason"
              v-validate="'required'"
              return-object
              :error-messages="errors.collect('cancellationReason')"
            ></v-select>
          </v-col>
          <v-col class="pr-4" cols="12" md="6">
            <v-select
              v-model="vm.billing.priceLimit"
              :items="priceLimits"
              label="Price Limit default"
              data-vv-as="priceLimit"
              name="priceLimit"
              item-text="description"
              v-validate="'required'"
              return-object
              :error-messages="errors.collect('gstType')"
            ></v-select>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  data: () => ({
    keys: ["programs"],
    items: [],
    dataKey: "",
    vm: { billing: {} },
    priceLimits: [
      { code: "priceLimitNationalNonRemote", description: "National non remote" },
      { code: "priceLimitNationalRemote", description: "National remote" },
      { code: "priceLimitVeryRemote", description: "Very remote" },
      { code: "priceLimitActNswQldVic", description: "ACT NSW QLD VIC" },
      { code: "priceLimitNtSaTasWa", description: "NT SA TAS WA" }
    ],
    gstTypes: [
      { code: "P1", description: "Tax Claimable (10%)" },
      { code: "P2", description: "GST Free" },
      { code: "P5", description: "GST out of Scope" }
    ],
    claimTypes: [
      { code: "", description: "Standard Service Charges" },
      { code: "CANC", description: "Cancellation Charges" },
      { code: "REPW", description: " Report Writing Charges" },
      { code: "TRAN", description: "Travel Charges" }
    ],
    cancelationReasons: [
      { code: "NSDH", description: "No show due to health reason" },
      { code: "NSDF", description: "No show due to family issues" },
      {
        code: "NSDT",
        description: "No show due to unavailability of transport"
      },
      { code: "NSDO", description: "No show - Other" }
    ],
    contactTypes: [
      "Phone",
      "Email",
      "Meeting/Appointment",
      "Report Writing",
      "Travel"
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    init() {
      if (
        this.$store.getters.currentOrganisation == undefined ||
        this.$store.getters.currentOrganisation.defaults == undefined
      ) {
        this.vm = { billing: {} };
      } else {
        this.vm = this.$store.getters.currentOrganisation.defaults;
        if (this.vm.billing == undefined) {
          this.vm.billing = {};
        }
      }
    },
    validateBeforeSubmit() {
      let me = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          if (me.vm.billing.claimType.code == "CANC") {
            me.vm.cancellationReason = null;
          }
          const org = me.currentOrganisation;
          org.defaults = me.vm;
          me.$store.dispatch("setCurrentOrganisation", org);
          this.$store.dispatch("setToast", {
            message: "Save success",
            color: "green darken-4"
          });
          return;
        } else {
          me.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    close() {
      this.$router.push("/admin");
    }
  }
};
</script>
